<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useAppConfigurationStore } from "@/stores/appconfig";

const ready = ref(false);
const uploadUrl = ref("");
const importType = ref("");
const checkEnums = ref(false);
const partyName = ref("");
const errors = ref("");
const dropZone = ref("");

const authStore = useAuthStore();
const appconfigStore = useAppConfigurationStore();

onMounted(() => {
  uploadUrl.value = `${appconfigStore.data.portalUrl}import/uploadFile`;
  setAuthorization();
});

async function setAuthorization() {
  // Authorization header kan hier pas gezet worden als deze in de template zet gaat ie verloren in de init van dropzone
  const token = await authStore.getAccessToken();
  //console.log("Import setAuthorization token", token);
  dropZone.value.dropzone.options.headers = { Authorization: `Bearer ${token}` };
  //console.log("Import setAuthorization setOption", dropZone.value.dropzone);
}

function startImport() {
  errors.value = "";
  dropZone.value.processQueue();
}

function onFileAdded(file) {
  ready.value.value = !file != null;
}

function onSend(file, xhr, formData) {
  let commandLine = "";
  if (importType.value != "") {
    commandLine += "/" + importType.value;
    if (importType.value == "innaxonlinemaatregelen") commandLine += " " + partyName.value;
  }
  if (checkEnums.value) commandLine += " /enums";
  formData.append("commandLine", commandLine);
}

function onUploaded(file, response) {
  console.log("onUploaded, file, response: ", response, file);
  errors.value = "";
  if (response.status === "OK" || response.status === "200" || response.success) {
    alert(`Import ok (${response.results.length})`);
    dropZone.value.removeAllFiles();
    ready.value = false;
  } else {
    alert("import failed " + response.err);
    console.log(response.message);
  }
}

function onUploadError(file, message, xhr) {
  errors.value = message;
  console.log("onUploadError, file, xhr: ", file, xhr);
  console.log("Message ====> " + JSON.stringify(message));
}
</script>

<template>
  <v-container fluid pa-0 fill-height fill-width>
    <v-row justify="center">
      <form id="adm-import-form" @submit.prevent="startImport()">
        <v-card>
          <v-card-title class="headline">
            <span class="headline"><h1>Importeren</h1></span>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-container class="register-form">
                <v-row>
                  <v-col>
                    <h3 class="large">Kies importbestand</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vue-dropzone
                      id="file-dropzone"
                      ref="dropZone"
                      v-on:vdropzone-success="onUploaded"
                      v-on:vdropzone-error="onUploadError"
                      v-on:vdropzone-file-added="onFileAdded"
                      v-on:vdropzone-sending="onSend"
                      :options="{
                        url: uploadUrl,
                        acceptedFiles: '.xls,.xlsx',
                        maxFilesize: 10000000,
                        maxFiles: 1,
                        thumbnailWidth: 150,
                        thumbnailHeight: 150,
                        addRemoveLinks: true,
                        autoProcessQueue: false,
                        dictDefaultMessage: 'Sleep uw bestand hier naar toe of klik om te uploaden. Toegestane formaten: .xls, .xlsx',
                      }"
                    >
                    </vue-dropzone>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group v-model="importType">
                      <!--<v-radio label="maatregelen" value="maatregelen"/>-->
                      <!--<v-radio label="nlsfb" value="nlsfb"/>-->
                      <!--<v-radio label="im" value="im"/>-->
                      <v-radio label="innaxonlinemaatregelen" value="innaxonlinemaatregelen" />
                      <v-radio label="party" value="party" />
                      <v-radio label="usages" value="usages" />
                    </v-radio-group>
                  </v-col>
                  <v-col>
                    <v-checkbox v-model="checkEnums" label="checkEnums" />
                    <v-text-field v-if="importType == 'innaxonlinemaatregelen'" v-model="partyName" label="Party" />
                  </v-col>
                </v-row>
                <v-row v-if="errors != null && errors != ''">
                  <pre>{{ errors }}</pre>
                </v-row>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark type="submit" class="primary" title="Opslaan" style="margin-right: 20px" :disabled="!ready">
              <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
              Importeren
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-row>
  </v-container>
</template>
